
   <template>
    <div id="inside">
    <div v-bind:class="containerClass">
      <div class="row mt-3" style="">
        <div class="col-6 col-md-5" style="">
          <h5>Olá, {{ formatDocument(user.DocNumber) }} - {{ user.Fullname }}. </h5>
          <p class="mb-0">Consulte suas pendências e aproveite nossas condições de negociação.</p>
        </div>
        <!--<div class="col-6 col-md-4" style="" v-if="containMenu(2)">
          <p class="m-0 small">Pendências totais:</p>
          <h4 class="border-info" style="">R$ {{ formatValue(totalDivida) }}</h4>
        </div>-->
      </div>


      <div class="row" style="">
        <div class="col-md-12 col-12" style="">
          <hr>
        </div>
      </div>

      <div class="row">
        <div class="">
        </div>
        <div v-bind:class="colPageClass" style="" v-if="containMenu(1)">
          <div class="card">
            <div class="card-header text-light bg-grey" style=""><i class="fa fa-fw mr-2 fa-file-text-o"></i>&nbsp;Boletos ativos</div>
            <div class="card-body" style="" v-if="loadBoletos">
              <div class="row">
                <div class="col-md-12 col-12">
                  <h5 class=""></h5>
                </div>
              </div>
              <div class="row">
                  <div class="justify-center" style="margin-top:5px;margin-left:0px;width:100% !important;">
                      <ProgressLoading v-bind:loading="loadBoletos"/>
                  </div>
              </div>
            </div>
            <div class="card-body" style="" v-if="!loadBoletos && boletos.length == 0">
              <div class="row">
                  <div class="justify-center">
                      <p class="text-info mb-0">Você não possui boletos ativos</p>
                  </div>
              </div>
            </div>
            <div v-if="!loadBoletos">
              <div v-for="boleto in boletos" :key="boleto.AccountNumber">
                <div class="card-body" v-for="deal in boleto.deals" :key="deal.AccountNumber" >
                  <div class="row">
                    <div class="col-md-12 col-12">
                      <h6 class="">Origem do boleto</h6>
                      <p>Número de Parcelas {{deal.installments}}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6 col-6">
                          <p class="m-0 text-info">Valor:</p>
                          <h6>R$ {{ formatValue(deal.value) }}</h6>
                        </div>
                        <div class="col-md-6 col-6">
                          <p class="m-0 text-info">Vencimento:</p>
                          <h6>{{ formatData(deal.dueDate, "YYYY-MM-DD", "L") }}</h6>
                        </div>
                      </div>
                      <p class="text-info mb-0">Contrato:</p>
                      <p class="mb-1">{{ boleto.contractNumber }}</p>
                      <p class="text-info mb-0" style="">Decrição:</p>
                      <p contenteditable="true">{{ boleto.accountNotes }}</p>
                      <p class="text-info mb-1">Clique na linha editável para copiar</p>
                      <p class="mb-4 digital-line" @click="copiarLinhaDigitavel(deal)"> 
                        {{deal.linhaDigitavel}}
                      <i class="fa fa-copy fa-fw"></i>
                      </p>
                      <!--<a class="btn mr-1 mb-1 btn-outline-primary a-withouthref col-xs-12 col-sm-3 col-md-auto" @click="billDownload(deal)"><i class="fa fa-download fa-fw"></i> Baixar boleto</a>-->
                      <a class="btn mr-1 mb-1 btn-outline-primary a-withouthref col-xs-12 col-sm-3 col-md-auto" @click="sendSms(deal)"><i class="fa fa-mobile fa-fw"></i> Receber por sms</a>
                      <!--<a class="btn mr-1 mb-1 btn-outline-primary a-withouthref col-xs-12 col-sm-3 col-md-auto" @click="sendEmail(deal)"><i class="fa fa-envelope fa-fw"></i> Receber por email</a>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            
            
          </div>
        </div>
        <div v-bind:class="colPageClass" v-if="containMenu(2)">
          <div class="card">
            <div class="card-header bg-blue"><i class="fa fa-folder-open-o fa-fw mr-2"></i> Contratos Negociáveis</div>
            <div class="card-body" style="" v-if="loadDividas">
              <div class="row">
                <div class="col-md-12 col-12">
                  <h5 class=""></h5>
                </div>
              </div>
              <div class="row">
                  <div class="justify-center" style="margin-top:5px;margin-left:0px;width:100% !important;">
                      <ProgressLoading v-bind:loading="loadDividas"/>
                  </div>
              </div>
            </div>
            <div class="card-body" style="" v-if="!loadDividas && dividas.length == 0">
              <div class="row">
                  <div class="justify-center">
                      <p class="text-info mb-0">Você não possui contratos negociáveis</p>
                  </div>
              </div>
            </div>
            <div class="card-body" style="" v-for="divida in dividas" :key="divida.AccountNumber">
              <div class="row">
                <div class="col-md-12 col-12">
                  <h5 class="">{{ divida.AccountNotes }}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-6">
                  <p class="m-0 text-info">Valor:</p>
                  <h5>R$ {{ formatValue(divida.value) }}</h5>
                </div>
                <div class="col-6 col-md-6">
                  <p class="m-0">Contrato: {{ divida.contractNumber }}</p>
                  <p class="text-info">Data: {{ formatData(divida.dueDate, "YYYY-MM-DD", "L") }}</p>
                  <router-link :to="{ name: 'negociarDivida', params: {
                    contractInfo: divida}}"> <a class="btn btn-secondary mr-1 mb-1" >Negociar</a></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--<div class="col-md-4" style="">
          <div class="card">
            <div class="card-header text-light bg-orange" style=""><i class="fa fa-fw mr-2 fa-handshake-o"></i> Acordos concluídos</div>
            <div class="card-body" style="">
              <div class="row">
                <div class="col-md-12 col-12">
                  <h6 class="">Cartão de crédito</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-7">
                  <p class="m-0 text-info">Valor:</p>
                  <h6>R$ 2.400,00</h6>
                  <p class="m-0">Contrato: 3312.0012/18</p>
                  <p class="text-info">Data: 12/09/2018</p>
                </div>
                <div class="col-md-6 col-5">
                  <a class="btn mr-1 mb-1 btn-outline-primary"  style="">Detalhes</a><a class="btn mr-1 mb-1 btn-primary" >Ação 2</a>
                </div>
              </div>
            </div>
            
            <div class="card-body gf2" style="">
              <div class="row">
                <div class="col-md-12 col-12 mx-auto text-center">
                  <a class="btn btn-outline-dark" >Ver todos</a>
                </div>
              </div>
            </div>
          </div>
        </div>-->

        <div class="col-12 col-md-3" style="">
          <ContentMenu></ContentMenu>
        </div>
      
      </div>


    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    name: "Acordo",
    components: {
    },
    data: () => ({
        loading: false,
        acordo: null,
        error: null
    }),
    created() {
        this.fetchData();
    },
    watch: {
        '$route': 'fetchData'
    },
    computed: {
        
    },
    methods: {
        ...mapActions('meusAcordos', ['ActionFindAcordoById']),

        async fetchData() {
            this.error =  this.acordo = null;
            this.loading = true;
            const fetchedId = this.$route.params.acordo_id;

            try {
                const response = await this.ActionFindAcordoById(fetchedId);
                if (response.status === 404) {
                    this.$router.push({ name: 'notFound'});
                }
                this.acordo = response;
                this.loading = false;
            } catch (error) {
                console.log(error)
            }
        }
    },
};
</script>